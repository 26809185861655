<template>
    <div class="modal-force-feedback">
        <div class="title">이번 만남 어떠셨나요?</div>
        <div class="meeting-box m-t-16">
            <img :src="scheduleInfo.photo" alt="" />
            <div class="meeting-info">
                <div class="meeting-desc">
                    <span>{{ scheduleInfo.name }}</span>
                    <span>{{ scheduleInfo.age }}세</span>
                </div>
                <div class="meeting-desc">
                    <span>{{ scheduleInfo.schedule_date }}</span>
                    <span>{{ scheduleInfo.schedule_time }}시</span>
                </div>
                <div class="meeting-desc">
                    <span>{{ scheduleInfo.schedule_place }}에서 {{ scheduleInfo.schedule_type }} 예정</span>
                </div>
            </div>
        </div>
        <div class="body">피드백 내용은 상대방에게 공개되지 않으니 솔직하게 답변해주세요!</div>
        <div class="button-area">
            <button v-for="(btn, idx) in buttons" :key="idx" :class="btn.class" @click="close(btn.id)">
                {{ btn.label }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalForceFeedback',
    props: ['options'],
    data: () => ({}),
    computed: {
        buttons() {
            return [
                {
                    label: '매니저에게 문의',
                    id: 1,
                    class: 'no',
                },
                {
                    label: '피드백 바로 진행',
                    id: 2,
                    class: 'yes',
                },
            ]
        },
        scheduleInfo() {
            const arr = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
            const s = this.options.schedule
            return {
                name: s.name,
                age: this.$options.filters.asAge(s.birthday),
                job: s.job,
                schedule_place: s.schedule.meet_place || '미정',
                schedule_date: s.schedule.date_confirmed
                    ? this.$moment(s.schedule.date_confirmed).format('YY/MM/DD') +
                      ' ' +
                      this.$translate(arr[this.$moment(s.schedule.date_confirmed).day() % 7])
                    : '미정',
                schedule_time:
                    (this.$moment(s.schedule.date_confirmed).format('A') === 'AM' ? '오전' : '오후') +
                    ' ' +
                    this.$moment(s.schedule.date_confirmed).format('h'),
                schedule_type: s.schedule.meet_type_confirmed
                    ? this.$translate(s.schedule.meet_type_confirmed.toUpperCase())
                    : null,
                user_status: !s.enabled && s.hidden ? 'withdrawn' : s.is_dormant ? 'dormant' : 'active',
                photo: s.urls[0],
            }
        },
    },

    methods: {
        close(idx) {
            this.$emit('update:options', { preventClose: false })
            this.$emit('close', idx)
        },
    },
}
</script>

<style scoped lang="scss">
.modal-force-feedback {
    width: 100%;
    padding: 16px;
    .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #111111;
        margin: 4px 0px;
    }
    .meeting-box {
        width: 100%;
        background: #f7f8f9;
        border-radius: 12px;
        display: flex;
        img {
            width: 80px;
            height: 80px;
            border: 1px solid #e9e9ed;
            box-sizing: border-box;
            border-radius: 8px;
            object-fit: cover;
            margin: 8px;
            margin-right: 12px;
        }
        .meeting-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #111111;
            .meeting-desc {
                span:not(:last-child)::after {
                    content: ', ';
                }
            }
        }
    }
    .body {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #111111;
        margin-top: 8px;
    }
    .button-area {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        padding-top: 20px;
        display: flex;
        justify-content: flex-end;
        .no {
            border: none;
            color: #111111;
            font-weight: 400;
            margin-right: 24px;
        }
        .yes {
            border: none;
            color: #04c9b2;
        }
    }
}
</style>
